import { AnalyticsContextProps } from 'context/analyticsContext';

type CreateAnalyticsLabelStringProps = {
  selectedFormat?: string;
  selectedTopic?: string;
};

export const createAnalyticsLabelString = ({
  selectedFormat,
  selectedTopic,
}: CreateAnalyticsLabelStringProps) => {
  let label = '';
  if (selectedFormat) {
    label += `Format:${selectedFormat}`;
  }
  if (selectedTopic) {
    label += ` Topic:${selectedTopic}`;
  }
  return label.trim();
};

type SendAnalyticsFilterEventProps = {
  sendAnalyticsEvent: AnalyticsContextProps;
  selectedFormat?: string;
  selectedTopic?: string;
};

export const sendAnalyticsFilterEvent = ({
  selectedFormat,
  selectedTopic,
  sendAnalyticsEvent,
}: SendAnalyticsFilterEventProps) => {
  if (selectedFormat || selectedTopic) {
    const label = createAnalyticsLabelString({
      selectedFormat,
      selectedTopic,
    });
    sendAnalyticsEvent({
      category: 'Filters',
      action: 'Filters applied',
      label,
    });
  }
};
