import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Heading } from '@chakra-ui/react';
import { UserContext } from 'context/userContext';
import { getLoginUrl } from 'helpers/login';
import { oauth } from 'config/amplify-config';

function Login() {
  const navigate = useNavigate();
  const { userId } = useContext(UserContext);

  const handleLoginAttempt = () => {
    window.location.href = getLoginUrl(oauth);
  };

  useEffect(() => {
    if (userId) {
      navigate('/');
    }
  }, [userId, navigate]);

  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box mb="70px">
        <Heading size="xl" fontWeight={300}>
          Welcome to
        </Heading>
        <Heading size="2xl" fontWeight={500}>
          Content<span style={{ fontWeight: 700 }}>HUB</span>
        </Heading>
      </Box>
      <Box
        maxWidth="280px"
        height="160px"
        display="flex"
        flexDir="column"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Button
          data-testid="buttonLogin"
          fontWeight={500}
          onClick={handleLoginAttempt}
        >
          Login
        </Button>
      </Box>
    </Box>
  );
}

export default Login;
