import { useContext, createElement } from 'react';
import {
  Box,
  AccordionIcon,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react';
import {
  formatList,
  getColourForFormat,
  getIconForFormat,
} from 'helpers/format';
import { AnalyticsContext } from 'context/analyticsContext';

type FormatSelectorProps = {
  selectedFormat?: string;
  setSelectedFormat: (value: string) => void;
  setOffset: (value: number) => void;
};

function FormatSelector({
  selectedFormat,
  setSelectedFormat,
  setOffset,
}: FormatSelectorProps) {
  const sendAnalyticsEvent = useContext(AnalyticsContext);

  const onSelect = (format: string) => {
    sendAnalyticsEvent({
      category: 'Format',
      action: `Format changed`,
      label: `${format}`,
    });
    setSelectedFormat(format);
    setOffset(0);
  };

  const toggleFormat = (format: string) => {
    onSelect(selectedFormat === format ? '' : format);
  };

  return (
    <AccordionItem>
      <h2>
        <AccordionButton data-testid="buttonOpenFormats">
          <Box flex="1" textAlign="left">
            Format
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <Grid
          role="radiogroup"
          templateColumns={[
            'repeat(2, 1fr)',
            'repeat(3, 1fr)',
            'repeat(4, 1fr)',
            'repeat(2, 1fr)',
          ]}
          gap={3}
        >
          {formatList.map((format) => (
            <GridItem
              data-testid={`buttonFormat${format}`}
              onClick={() => toggleFormat(format)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  toggleFormat(format);
                }
              }}
              role="radio"
              aria-checked={selectedFormat === format}
              tabIndex={0}
              aria-labelledby={`${format}-label`}
              borderColor={
                selectedFormat === format
                  ? `${getColourForFormat(format)}.300`
                  : 'grey.200'
              }
              borderWidth={selectedFormat === format ? 2 : 1}
              height={20}
              key={`format-${format}`}
              borderRadius={5}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDir="column"
              cursor="pointer"
            >
              <Box display="inline-block" m={1.5}>
                {createElement(getIconForFormat(format), {
                  boxSize: 7,
                  color: `${getColourForFormat(format)}.500`,
                })}
              </Box>
              <Text
                color={`${getColourForFormat(format)}.500`}
                fontWeight={selectedFormat === format ? 500 : 300}
                fontSize=".8rem"
                id={`${format}-label`}
              >
                {format}
              </Text>
            </GridItem>
          ))}
        </Grid>
      </AccordionPanel>
    </AccordionItem>
  );
}

export default FormatSelector;
