import { axiosInstance as axios } from 'config/axios';

import {
  mapCatalogueToolTitle,
  mapCuration,
  mapManyCurations,
} from 'helpers/catalogue';
import { CuratedItemRes } from 'types/curatedItem';
import { CurationType } from 'types/curationTypes';
import { Title, TitleRes } from 'types/title';

type RequestProps = {
  limit: number;
  offset: number;
  format?: string;
  keyword?: string;
  type?: CurationType;
  topicId?: string;
};

export const catalogueRepository = {
  async getAllTopics() {
    const getAllTopicsResponse = await axios.get('/topics?limit=0&offset=0');
    return getAllTopicsResponse.data;
  },
  async getAllTitles({
    topicId,
    format,
    keyword,
    limit,
    offset,
  }: RequestProps) {
    let getTitlesURL = `/titles?limit=${limit}&offset=${offset}&publishType=FULL_PUBLISH`;

    if (format) {
      getTitlesURL += `&format=${format}`;
    }
    if (topicId) {
      getTitlesURL += `&topicId=${topicId}`;
    }
    if (keyword) {
      getTitlesURL += `&keyword=${keyword}`;
    }

    const getAllTitlesResponse = await axios.get(getTitlesURL);

    const { data: titles } = getAllTitlesResponse;

    const sortedData = titles.data.map((title: TitleRes) =>
      mapCatalogueToolTitle(title),
    );

    return {
      sortedData,
      total: titles.total,
    };
  },

  async getTitleById(callback: (value: Title) => void, id?: string) {
    const getTitlesURL = `/titles/${id}`;

    const { data } = await axios.get(getTitlesURL);

    const mappedTitle = mapCatalogueToolTitle(data);

    callback(mappedTitle);
  },

  async getDownloadAssetURL(assetId?: string) {
    const { data: url } = await axios.get(
      `/assets/getDownloadAssetUrl/${assetId}`,
    );
    return url;
  },

  async getAssetPresignedURL(assetId: string) {
    const { data: url } = await axios.get(
      `/assets/getPreviewAssetUrl/${assetId}`,
    );
    return url;
  },

  async getAllCampaigns({ limit, offset }: RequestProps) {
    const { data } = await axios.get(
      `/campaigns?limit=${limit}&offset=${offset}`,
    );

    const sortedData = data.data.map((campaign: CuratedItemRes) =>
      mapManyCurations(CurationType.Campaign, campaign),
    );
    return {
      sortedData,
      total: data.total,
    };
  },

  async getCampaignById(id?: string) {
    const { data: campaign } = await axios.get(`/campaigns/${id}`);
    return mapCuration(CurationType.Campaign, campaign);
  },

  async getAllPathways({ limit, offset }: RequestProps) {
    const { data } = await axios.get(
      `/pathways?limit=${limit}&offset=${offset}`,
    );

    const sortedData = data.data.map((pathway: CuratedItemRes) =>
      mapManyCurations(CurationType.Pathway, pathway),
    );
    return {
      sortedData,
      total: data.total,
    };
  },

  async getPathwayById(id?: string) {
    const { data: pathway } = await axios.get(`/pathways/${id}`);
    return mapCuration(CurationType.Pathway, pathway);
  },

  getCurations({ type, ...props }: RequestProps) {
    if (type === CurationType.Campaign) {
      return this.getAllCampaigns(props);
    }
    return this.getAllPathways(props);
  },

  getCurationById(type: CurationType, id?: string) {
    if (type === CurationType.Campaign) {
      return this.getCampaignById(id);
    }
    return this.getPathwayById(id);
  },
};
