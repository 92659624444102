import { BrowserRouter as Router } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/provider';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/300.css';
import AppRoutes from 'router/AppRoutes';
import LoginWrapper from 'components/pages/LoginWrapper/component';
import AuthInterceptor from 'components/pages/AuthInterceptor/AuthInterceptor';
import theme from './theme';

function App() {
  return (
    <Router>
      <ChakraProvider theme={theme}>
        <AuthInterceptor>
          <LoginWrapper>
            <AppRoutes />
          </LoginWrapper>
        </AuthInterceptor>
      </ChakraProvider>
    </Router>
  );
}

export default App;
