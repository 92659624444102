import { Box, Spinner } from '@chakra-ui/react';
import { Asset } from 'types/asset';

type ImagePreviewFrameProps = {
  asset: Partial<Asset>;
};

function ImagePreviewFrame({ asset }: ImagePreviewFrameProps) {
  if (!asset) {
    return (
      <Box p={10} textAlign="center">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <img
      alt="preview"
      style={{
        backgroundColor: 'black',
        width: 'auto',
        maxWidth: '1200px',
        maxHeight: '40vh',
        margin: '0 auto',
      }}
      src={asset.url}
    />
  );
}

export default ImagePreviewFrame;
