import { useColorModeValue } from '@chakra-ui/react';
import { format } from 'date-fns';
import { CuratedItem } from 'types/curatedItem';
import HeaderSection from 'components/ui/HeaderSection/component';

type CurationHeaderProps = {
  colorScheme: string;
  curation: CuratedItem;
};

function CurationHeader({ curation, colorScheme }: CurationHeaderProps) {
  const color = useColorModeValue(`${colorScheme}.800`, `${colorScheme}.50`);
  const backgroundColor = useColorModeValue(
    `${colorScheme}.100`,
    `${colorScheme}.900`,
  );

  const getTags = () => {
    const tags = [];
    tags.push(curation.type);
    if (curation.isCampaign) {
      tags.push(
        `${format(curation.startDate, 'LLL do')} - ${format(
          curation.endDate,
          'LLL do',
        )}`,
      );
    }
    tags.push(`${curation.numItems} Items`);
    tags.push(`Updated ${format(curation.lastUpdated, 'LLL do yyyy')}`);

    return tags;
  };

  return (
    <HeaderSection
      heading={curation.name}
      summary={curation.summary}
      imageUrl={curation.imageUrl}
      imageAlt={curation.name}
      color={color}
      backgroundColor={backgroundColor}
      tags={getTags()}
    />
  );
}

export default CurationHeader;
