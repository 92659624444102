import ReactMarkdown from 'react-markdown';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import {
  Box,
  Heading,
  Link,
  ListItem,
  SimpleGrid,
  UnorderedList,
  useColorModeValue,
} from '@chakra-ui/react';
import { markdownTheme } from 'helpers/general';
import ItemCard from 'components/ui/ItemCard/component';
import { CuratedSectionItem } from 'types/curatedItem';
import { QueuedDownloads } from 'types/queuedDownloads';

type CurationSectionProps = {
  section: CuratedSectionItem;
  colorScheme: string;
  queuedDownloads: QueuedDownloads;
  setQueuedDownloads: (value: QueuedDownloads) => void;
};

function CurationSection({
  section,
  colorScheme,
  queuedDownloads,
  setQueuedDownloads,
}: CurationSectionProps) {
  const { description, urls } = section.resources || {};

  const color = useColorModeValue(`${colorScheme}.800`, `${colorScheme}.50`);
  const backgroundColor = useColorModeValue(
    `${colorScheme}.100`,
    `${colorScheme}.900`,
  );

  return (
    <Box
      my={10}
      borderColor={backgroundColor}
      display="flex"
      flexDir={['column', 'column', 'row']}
    >
      <Box
        data-testid="bodySectionInfoBox"
        borderLeftWidth={4}
        pl={3}
        width={['auto', 'auto', '40vw']}
        mr={[0, 0, 5]}
        mb={5}
      >
        <Box tabIndex={0}>
          <Heading color={color} fontWeight={500} as="h2" mb={4} size="lg">
            {section.name}
          </Heading>
          <ReactMarkdown components={ChakraUIRenderer(markdownTheme(color))}>
            {section.summary}
          </ReactMarkdown>
        </Box>
        {section.resources && (
          <Box
            data-testid="furtherResources"
            p={3}
            backgroundColor={backgroundColor}
            mt={5}
            fontSize="sm"
            borderRadius={5}
            tabIndex={0}
          >
            <Heading color={color} size="sm" mb={3} fontWeight={500}>
              Further Resources
            </Heading>

            <Box fontWeight={300}>
              {description && <div>{description}</div>}
              {urls && (
                <UnorderedList spacing={3} color={color} fontWeight={500}>
                  {urls.map(({ url, urlDescription }) => (
                    <ListItem key={url}>
                      <Link href={url}>{urlDescription}</Link>
                    </ListItem>
                  ))}
                </UnorderedList>
              )}
            </Box>
          </Box>
        )}
      </Box>
      <Box width={['auto', 'auto', '55vw']}>
        {section.titles && (
          <SimpleGrid
            data-testid="sectionTitles"
            minW={500}
            mt={0}
            columns={4}
            spacing={4}
            minChildWidth="250px"
            alignItems="center"
          >
            {section.titles.map((item) => (
              <ItemCard
                key={item.id}
                renderSmall
                item={item}
                queuedDownloads={queuedDownloads}
                setQueuedDownloads={setQueuedDownloads}
              />
            ))}
          </SimpleGrid>
        )}
      </Box>
    </Box>
  );
}

export default CurationSection;
