import { useLocation, useNavigate } from 'react-router';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Flex, IconButton, Select, Text } from '@chakra-ui/react';

import { LIMITS } from './Pagination.constants';
import { handleSelectChange, paginationText } from './Pagination.utils';

type PaginationProps = {
  limit: number;
  offset: number;
  total: number;
  setLimit: (value: number) => void;
  setOffset: (value: number) => void;
};

function Pagination({
  limit,
  setLimit,
  offset,
  setOffset,
  total,
}: PaginationProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const updatePageParams = (newOffset: number, newLimit = limit) => {
    navigate(`${location.pathname}?offset=${newOffset}&limit=${newLimit}`, {
      replace: true,
    });
  };

  const onNext = () => {
    const newOffset = offset + Number(limit);
    setOffset(newOffset);
    updatePageParams(newOffset);
  };

  const onPrev = () => {
    const newOffset = offset - Number(limit);
    setOffset(newOffset);
    updatePageParams(newOffset);
  };

  return (
    <Flex direction="row" gap="2" justifyContent="end" alignItems="center">
      <Text fontSize="xs">Rows per page:</Text>
      <div style={{ width: 'unset' }}>
        <Select
          variant="unstyled"
          value={limit}
          onChange={(event) => {
            const value = Number(event.target.value);
            handleSelectChange({
              value,
              setLimit,
              setOffset,
            });
            updatePageParams(0, value);
          }}
          size="xs"
          aria-label="Select rows per page"
        >
          {LIMITS.map((l) => (
            <option key={l} value={l}>
              {l}
            </option>
          ))}
        </Select>
      </div>
      <Text fontSize="xs">
        {paginationText({ offset, limit, total })} of {total}
      </Text>
      <IconButton
        data-testid="iconArrowLeft"
        isDisabled={offset === 0}
        onClick={() => onPrev()}
        variant="unstyled"
        aria-label="Previous page"
        icon={<ChevronLeftIcon />}
      />
      <IconButton
        data-testid="iconArrowRight"
        isDisabled={offset + Number(limit) >= total}
        onClick={() => onNext()}
        variant="unstyled"
        aria-label="Next page"
        icon={<ChevronRightIcon />}
      />
    </Flex>
  );
}

export default Pagination;
