import { useSearchParams } from 'react-router-dom';

export const usePaginationRouteParams = () => {
  const [queryString] = useSearchParams();

  const offset = Number(queryString.get('offset')) || 0;
  const limit = Number(queryString.get('limit')) || 25;

  return { offset, limit };
};
