import { useLocation } from 'react-router-dom';
import { Box, Spinner } from '@chakra-ui/react';
import { useStatefulApi } from 'hooks/useStatefulApi';
import { CurationType } from 'types/curationTypes';
import { CuratedItem as CuratedItemType } from 'types/curatedItem';
import { QueuedDownloads } from 'types/queuedDownloads';
import { catalogueRepository } from 'services/catalogueRepository';
import CurationSection from 'components/ui/CurationSection/component';
import BackButton from 'components/ui/BackButton/component';
import CurationHeader from './CurationHeader/component';

type CuratedItemProps = {
  queuedDownloads: QueuedDownloads;
  type: CurationType;
  setQueuedDownloads: (value: QueuedDownloads) => void;
};

function CuratedItem({
  queuedDownloads,
  setQueuedDownloads,
  type,
}: CuratedItemProps) {
  const location = useLocation();

  const id = location.pathname.split('/').pop();

  const { data: curation, loading } = useStatefulApi<void, CuratedItemType>(
    () => catalogueRepository.getCurationById(type, id),
    [type, id, location],
  );

  if (loading || !curation) {
    return (
      <Box data-testid="loading-state" p={10} textAlign="center">
        <Spinner size="xl" />
      </Box>
    );
  }

  const colorScheme = curation.isCampaign ? 'teal' : 'purple';
  return (
    <>
      <BackButton />
      <CurationHeader curation={curation} colorScheme={colorScheme} />
      {curation?.sections &&
        curation.sections.map((section) => (
          <CurationSection
            key={section.id}
            section={section}
            colorScheme={colorScheme}
            queuedDownloads={queuedDownloads}
            setQueuedDownloads={setQueuedDownloads}
          />
        ))}
    </>
  );
}

export default CuratedItem;
