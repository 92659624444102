import { Box, Spinner } from '@chakra-ui/react';
import { Asset } from 'types/asset';

type VideoPreviewFrameProps = {
  asset: Partial<Asset>;
  poster?: string;
};

function VideoPreviewFrame({ asset, poster }: VideoPreviewFrameProps) {
  if (!asset) {
    return (
      <Box p={10} textAlign="center">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <video
      poster={poster}
      style={{
        backgroundColor: 'black',
        width: 'auto',
        maxWidth: '1200px',
        maxHeight: '60vh',
        margin: '0 auto',
      }}
      controls
    >
      <source src={asset.url} type={asset.type} />
      <track kind="captions" label="video" />
    </video>
  );
}

export default VideoPreviewFrame;
