import { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/300.css';
import { CurationType } from 'types/curationTypes';
import { QueuedDownloads } from 'types/queuedDownloads';
import { Topic } from 'types/topic';
import CatalogueIndex from 'components/pages/CatalogueIndex/CatalogueIndex';
import CuratedIndex from 'components/pages/CuratedIndex/CuratedIndex';
import TitleDetail from 'components/pages/TitleDetail/component';
import CuratedItem from 'components/pages/CuratedItem/CuratedItem';
import Login from 'components/pages/Login/component';
import PrivateRoutes from './PrivateRoutes';

function AppRoutes() {
  const [queuedDownloads, setQueuedDownloads] = useState<QueuedDownloads>({});
  const [selectedTopic, setSelectedTopic] = useState<string>();
  const [selectedFormat, setSelectedFormat] = useState<string>();
  const [searchKeywords, setSearchKeywords] = useState<string>();
  const [topics, setTopics] = useState<Topic[]>([]);

  return (
    <Routes>
      <Route
        element={
          <PrivateRoutes
            queuedDownloads={queuedDownloads}
            setQueuedDownloads={setQueuedDownloads}
          />
        }
      >
        <Route
          path="/titles/:id"
          element={
            <TitleDetail
              queuedDownloads={queuedDownloads}
              setQueuedDownloads={setQueuedDownloads}
            />
          }
        />
        <Route
          path="/titles"
          element={
            <CatalogueIndex
              queuedDownloads={queuedDownloads}
              setQueuedDownloads={setQueuedDownloads}
              selectedTopic={selectedTopic}
              setSelectedTopic={setSelectedTopic}
              selectedFormat={selectedFormat}
              setSelectedFormat={setSelectedFormat}
              topics={topics}
              setTopics={setTopics}
              searchKeywords={searchKeywords}
              setSearchKeywords={setSearchKeywords}
            />
          }
        />
        <Route
          path="/campaigns/:id"
          element={
            <CuratedItem
              type={CurationType.Campaign}
              queuedDownloads={queuedDownloads}
              setQueuedDownloads={setQueuedDownloads}
            />
          }
        />
        <Route
          path="/campaigns"
          element={<CuratedIndex type={CurationType.Campaign} />}
        />
        <Route
          path="/pathways/:id"
          element={
            <CuratedItem
              type={CurationType.Pathway}
              queuedDownloads={queuedDownloads}
              setQueuedDownloads={setQueuedDownloads}
            />
          }
        />
        <Route
          path="/pathways"
          element={<CuratedIndex type={CurationType.Pathway} />}
        />
        <Route
          path="/"
          element={
            <CatalogueIndex
              queuedDownloads={queuedDownloads}
              setQueuedDownloads={setQueuedDownloads}
              selectedTopic={selectedTopic}
              setSelectedTopic={setSelectedTopic}
              selectedFormat={selectedFormat}
              setSelectedFormat={setSelectedFormat}
              topics={topics}
              setTopics={setTopics}
              searchKeywords={searchKeywords}
              setSearchKeywords={setSearchKeywords}
            />
          }
        />
      </Route>
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}

export default AppRoutes;
