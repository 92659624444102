import { createContext } from 'react';

type SendAnalyticsEventProps = {
  category: string;
  action: string;
  label: string;
};
export type AnalyticsContextProps = (props: SendAnalyticsEventProps) => void;

export const AnalyticsContext = createContext<AnalyticsContextProps>(
  () => null,
);
