import axios from 'axios';

declare module 'axios' {
  export interface AxiosRequestConfig {
    skipErrorHandler?: boolean;
  }
}

export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/clubhub`,
});
