import { format } from 'date-fns';
import { Box, useColorModeValue } from '@chakra-ui/react';
import { getColourForFormat } from 'helpers/format';
import HeaderSection from 'components/ui/HeaderSection/component';
import { QueuedDownloads } from 'types/queuedDownloads';
import { Title } from 'types/title';
import AddDownloadList from './AddDownloadList/component';

type TitleHeaderProps = {
  title: Title;
  queuedDownloads: QueuedDownloads;
  setQueuedDownloads: (value: QueuedDownloads) => void;
};

function TitleHeader({
  title,
  queuedDownloads,
  setQueuedDownloads,
}: TitleHeaderProps) {
  const colorScheme = getColourForFormat(title.format);
  const color = useColorModeValue(`${colorScheme}.700`, `${colorScheme}.200`);
  const backgroundColor = useColorModeValue(
    `${colorScheme}.100`,
    `${colorScheme}.900`,
  );

  const getTags = () => {
    const tags = [];
    tags.push(`#${title.code}`);
    tags.push(`Part of "${title.topic}"`);
    tags.push(`About ${title.durationMinutes} Minutes`);
    if (title.updatedDate > title.publishedDate) {
      tags.push(`Updated ${format(title.updatedDate, 'LLL do yyyy')}`);
    } else {
      tags.push(`Published ${format(title.publishedDate, 'LLL do yyyy')}`);
    }
    return tags;
  };

  return (
    <HeaderSection
      heading={title.title}
      summary={title.summary}
      imageUrl={title.imageUrl}
      imageAlt={title.imageAlt}
      color={color}
      backgroundColor={backgroundColor}
      tags={getTags()}
    >
      <Box data-testid="addToBasketBar" textAlign="center" marginY={5}>
        <AddDownloadList
          assets={title.assets}
          availableAs={title.availableAs}
          titleCode={title.code}
          queuedDownloads={queuedDownloads}
          setQueuedDownloads={setQueuedDownloads}
          colorScheme={colorScheme}
        />
      </Box>
    </HeaderSection>
  );
}

export default TitleHeader;
