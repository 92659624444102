import { extendTheme } from '@chakra-ui/react';

const Menu = {
  baseStyle: {
    list: {
      minW: '150px',
    },
  },
};
const Heading = {
  baseStyle: {
    fontWeight: 500,
  },
  sizes: {
    '2xl': {
      fontSize: ['1.5em', '2em', '2.3em'],
    },
    xl: {
      fontSize: '1.4em',
    },
    lg: {
      fontSize: '1.3em',
    },
  },
};
const Text = {
  baseStyle: {
    fontWeight: 300,
  },
};
const theme = extendTheme({
  components: {
    Menu,
    Heading,
    Text,
  },
  fonts: {
    heading: 'Poppins',
    body: 'Poppins',
  },
  colors: {
    burntorange: {
      50: '#ffded6',
      100: '#ffbdad',
      200: '#ff9d83',
      300: '#ff7c5a',
      400: '#ff5b31',
      500: '#e6522c',
      600: '#b34022',
      700: '#802e19',
      800: '#4c1b0f',
      900: '#190905',
    },
    teal: {
      50: '#87dde2',
      100: '#56cfd6',
      200: '#26c1ca',
      300: '#0ebac4',
      400: '#0da7b0',
      500: '#0b959d',
      600: '#0a8289',
      700: '#087076',
      800: '#075d62',
      900: '#064a4e',
    },
    neon: {
      50: '#f1fdb4',
      100: '#edfd9b',
      200: '#e3fb6a',
      300: '#dafa38',
      400: '#d1f906',
      500: '#a7c705',
      600: '#7d9504',
      700: '#546402',
      800: '#3f4b02',
      900: '#2a3201',
    },
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: true,
  },
});

export default theme;
