import { Box, Accordion } from '@chakra-ui/react';
import { Topic } from 'types/topic';
import TopicSelector from './TopicSelector/component';
import FormatSelector from './FormatSelector/component';

type SidePanelProps = {
  selectedTopic?: string;
  setSelectedTopic: (value: string) => void;
  selectedFormat?: string;
  setSelectedFormat: (value: string) => void;
  topics: Topic[];
  setTopics: (value: Topic[]) => void;
  setOffset: (value: number) => void;
};

function SidePanel({
  selectedTopic,
  setSelectedTopic,
  selectedFormat,
  setSelectedFormat,
  topics,
  setTopics,
  setOffset,
}: SidePanelProps) {
  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      alignSelf={['auto', 'auto', 'auto', 'flex-start']}
      position={['relative', 'relative', 'relative', 'sticky']}
      height="auto"
      top={5}
      flexBasis={1}
      minW={80}
    >
      <Accordion defaultIndex={[0, 1]} width="100%" allowMultiple>
        <TopicSelector
          selectedTopic={selectedTopic}
          setSelectedTopic={setSelectedTopic}
          topics={topics}
          setTopics={setTopics}
          setOffset={setOffset}
        />
        <FormatSelector
          selectedFormat={selectedFormat}
          setSelectedFormat={setSelectedFormat}
          setOffset={setOffset}
        />
      </Accordion>
    </Box>
  );
}

export default SidePanel;
