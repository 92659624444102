import { Icon, IconProps } from '@chakra-ui/icons';

function FacilitateIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <g clipPath="url(#clip0_1147_87)" fill="none" stroke="currentColor">
        <path
          d="M13.7678 10.9822C14.7441 11.9585 14.7441 13.5414 13.7678 14.5177C12.7915 15.494 11.2085 15.494 10.2322 14.5177C9.25592 13.5414 9.25592 11.9585 10.2322 10.9822C11.2085 10.0059 12.7915 10.0059 13.7678 10.9822Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.2538 4.98223C20.2302 5.95854 20.2302 7.54145 19.2538 8.51776C18.2775 9.49407 16.6946 9.49407 15.7183 8.51776C14.742 7.54145 14.742 5.95854 15.7183 4.98223C16.6946 4.00592 18.2775 4.00592 19.2538 4.98223"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 12.25C19.533 12.25 21 12.783 22 13.583"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.2818 4.98223C9.25811 5.95854 9.25811 7.54145 8.2818 8.51776C7.30549 9.49407 5.72258 9.49407 4.74627 8.51776C3.76996 7.54145 3.76996 5.95854 4.74627 4.98223C5.72258 4.00592 7.30549 4.00592 8.2818 4.98223"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 13.583C3 12.783 4.467 12.25 6 12.25"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.5911 19.75C15.4261 18.849 13.7521 18.25 12.0001 18.25C10.2481 18.25 8.57406 18.849 7.40906 19.75"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Icon>
  );
}

function ParticipateIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <g clipPath="url(#clip0_1147_89)" fill="none" stroke="currentColor">
        <path
          d="M8.93005 16L8.43005 20"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.428 9V5.428C20.428 4.08705 19.341 3 18 3H5.428V3C4.08705 3 3 4.08705 3 5.428V5.428V13.572V13.572C3 14.913 4.08705 16 5.428 16H13"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.5 21H15V21C14.7239 21 14.5 20.7761 14.5 20.5V20.094V20.094C14.5033 18.9389 15.4389 18.0033 16.594 18H19.906V18C21.0611 18.0033 21.9967 18.9389 22 20.094V20.5V20.5C22 20.7761 21.7761 21 21.5 21V21V21Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.25 15.5V15.5C17.1455 15.4978 16.2518 14.6006 16.254 13.496C16.2562 12.3914 17.1535 11.4978 18.258 11.5C19.3626 11.5022 20.2562 12.3994 20.254 13.504C20.2518 14.6055 19.3594 15.4978 18.258 15.5H18.25Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.93005 20H11.0001"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Icon>
  );
}

function ReadIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <g clipPath="url(#clip0_1147_88)" fill="none" stroke="currentColor">
        <path
          d="M17.35 2.536C17.862 2.583 18.25 3.021 18.25 3.535V8.667L16.5 7.5L14.75 8.667V3.559C14.75 3.051 15.13 2.621 15.635 2.561C15.953 2.523 16.274 2.501 16.596 2.501C16.848 2.5 17.099 2.513 17.35 2.536Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.25 3.71299C19.089 3.90599 19.891 4.22399 20.593 4.69999C20.857 4.87799 21 5.19099 21 5.50999V18.239C21 18.96 20.26 19.444 19.603 19.148C17.18 18.059 14.06 18.343 12 20C9.94 18.343 6.82 18.059 4.397 19.148C3.74 19.444 3 18.961 3 18.24V5.51399C3 5.19199 3.146 4.87599 3.413 4.69599C5.914 3.00599 9.648 3.10799 12 4.99999C12.792 4.36299 13.745 3.94699 14.75 3.71599"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 20V5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Icon>
  );
}

function WatchIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <g clipPath="url(#clip0_1147_85)" fill="none" stroke="currentColor">
        <path
          d="M15.493 18.25C14.527 18.25 13.743 19.034 13.75 20C13.75 20.966 14.534 21.75 15.5 21.75C16.466 21.75 17.25 20.966 17.25 20C17.25 19.034 16.466 18.25 15.493 18.25Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19 15H5C3.895 15 3 14.105 3 13V5C3 3.895 3.895 3 5 3H19C20.105 3 21 3.895 21 5V13C21 14.105 20.105 15 19 15Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 20H13.75"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.25 20H21"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1726 10.5585V7.44151C10.1725 7.35157 10.1963 7.26321 10.2414 7.18541C10.2865 7.10761 10.3514 7.04314 10.4295 6.99855C10.5076 6.95396 10.5962 6.93084 10.6861 6.93153C10.776 6.93223 10.8642 6.95672 10.9416 7.00251L13.5776 8.56151C13.6537 8.60664 13.7168 8.67081 13.7605 8.74769C13.8043 8.82457 13.8274 8.91153 13.8274 9.00001C13.8274 9.08849 13.8043 9.17545 13.7605 9.25233C13.7168 9.32921 13.6537 9.39337 13.5776 9.43851L10.9416 10.9975C10.8642 11.0433 10.776 11.0678 10.6861 11.0685C10.5962 11.0692 10.5076 11.0461 10.4295 11.0015C10.3514 10.9569 10.2865 10.8924 10.2414 10.8146C10.1963 10.7368 10.1725 10.6485 10.1726 10.5585V10.5585Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Icon>
  );
}

function ListenIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <g clipPath="url(#clip0_1147_86)" fill="none" stroke="currentColor">
        <path
          d="M14 11C14 10.6044 13.8827 10.2178 13.6629 9.88886C13.4432 9.55996 13.1308 9.30362 12.7654 9.15224C12.3999 9.00087 11.9978 8.96126 11.6098 9.03843C11.2219 9.1156 10.8655 9.30608 10.5858 9.58579C10.3061 9.86549 10.1156 10.2219 10.0384 10.6098C9.96126 10.9978 10.0009 11.3999 10.1522 11.7654C10.3036 12.1308 10.56 12.4432 10.8889 12.6629C11.2178 12.8827 11.6044 13 12 13C12.2626 13 12.5227 12.9483 12.7654 12.8478C13.008 12.7473 13.2285 12.6 13.4142 12.4142C13.6 12.2285 13.7473 12.008 13.8478 11.7654C13.9483 11.5227 14 11.2626 14 11V11Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 11C14 10.6044 13.8827 10.2178 13.6629 9.88886C13.4432 9.55996 13.1308 9.30362 12.7654 9.15224C12.3999 9.00087 11.9978 8.96126 11.6098 9.03843C11.2219 9.1156 10.8655 9.30608 10.5858 9.58579C10.3061 9.86549 10.1156 10.2219 10.0384 10.6098C9.96126 10.9978 10.0009 11.3999 10.1522 11.7654C10.3036 12.1308 10.56 12.4432 10.8889 12.6629C11.2178 12.8827 11.6044 13 12 13C12.2626 13 12.5227 12.9483 12.7654 12.8478C13.008 12.7473 13.2285 12.6 13.4142 12.4142C13.6 12.2285 13.7473 12.008 13.8478 11.7654C13.9483 11.5227 14 11.2626 14 11V11Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 18.4826C18.6054 17.4099 19.8234 15.8493 20.4739 14.0314C21.1244 12.2134 21.173 10.2344 20.6125 8.38675C20.052 6.53906 18.9122 4.92059 17.3613 3.77039C15.8105 2.62019 13.9308 1.99924 12 1.99924C10.0692 1.99924 8.18953 2.62019 6.63867 3.77039C5.08782 4.92059 3.94799 6.53906 3.38751 8.38675C2.82704 10.2344 2.87564 12.2134 3.52614 14.0314C4.17665 15.8493 5.39456 17.4099 7 18.4826"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 14.7672C7.2627 13.9859 6.77032 13.0058 6.58367 11.9479C6.39701 10.89 6.52424 9.80063 6.94965 8.81421C7.37507 7.82779 8.08004 6.98755 8.97753 6.39721C9.87503 5.80687 10.9258 5.49228 12 5.49228C13.0742 5.49228 14.125 5.80687 15.0225 6.39721C15.92 6.98755 16.6249 7.82779 17.0504 8.81421C17.4758 9.80063 17.603 10.89 17.4163 11.9479C17.2297 13.0058 16.7373 13.9859 16 14.7672"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.3499 16C12.5781 16 12.8039 16.0473 13.0129 16.139C13.2219 16.2308 13.4095 16.3648 13.5641 16.5328C13.7186 16.7007 13.8366 16.8989 13.9107 17.1148C13.9847 17.3307 14.0131 17.5596 13.9942 17.787L13.7796 20.3626C13.7424 20.8089 13.5388 21.225 13.2091 21.5284C12.8795 21.8317 12.4479 22 12 22V22C11.5521 22 11.1205 21.8317 10.7909 21.5284C10.4612 21.2251 10.2576 20.8089 10.2204 20.3626L10.0058 17.787C9.98687 17.5596 10.0153 17.3307 10.0893 17.1148C10.1634 16.8989 10.2814 16.7007 10.4359 16.5328C10.5905 16.3648 10.7782 16.2308 10.9871 16.1391C11.1961 16.0474 11.4219 16 11.6501 16H12.3499Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Icon>
  );
}

const FormatIcons = {
  FacilitateIcon,
  ParticipateIcon,
  ReadIcon,
  WatchIcon,
  ListenIcon,
};

export default FormatIcons;
