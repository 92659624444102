import React, { useEffect } from 'react';
import { amplifyConfig } from 'config/amplify-config';
import { axiosInstance } from 'config/axios';
import { AxiosError, InternalAxiosRequestConfig } from 'axios';

interface AuthInterceptorProps {
  children: React.ReactNode;
}

const Auth = amplifyConfig();

const AuthInterceptor = ({ children }: AuthInterceptorProps) => {
  useEffect(() => {
    const requestInterceptor = (
      config: InternalAxiosRequestConfig,
    ): Promise<InternalAxiosRequestConfig> =>
      new Promise((resolve, reject) => {
        Auth.currentSession().then((session) => {
          const validToken = session.isValid();

          if (validToken) {
            const token = session.getIdToken().getJwtToken();
            config.headers.set('Authorization', `Bearer ${token}`);
            resolve(config);
          } else {
            reject();
          }
        });
      });

    const errInterceptor = (error: AxiosError) => Promise.reject(error);

    const interceptor = axiosInstance.interceptors.request.use(
      requestInterceptor,
      errInterceptor,
    );

    return () => axiosInstance.interceptors.request.eject(interceptor);
  }, []);

  return children;
};

export default AuthInterceptor;
