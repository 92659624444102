import { useState, useEffect } from 'react';
import { Box, GridItem, Heading, Spinner } from '@chakra-ui/react';
import { useStatefulApi } from 'hooks/useStatefulApi';
import { catalogueRepository } from 'services/catalogueRepository';
import CuratedBanner from 'components/ui/CuratedBanner/component';
import Pagination from 'components/ui/Pagination/Pagination';
import EmptySection from 'components/ui/EmptySection/component';
import { CurationType } from 'types/curationTypes';
import { CuratedItemsData } from 'types/curatedItem';
import { usePaginationRouteParams } from 'hooks/usePaginationRouteParams';

type CuratedIndexProps = {
  type: CurationType;
};

function CuratedIndex({ type }: CuratedIndexProps) {
  const pageParams = usePaginationRouteParams();
  const [offset, setOffset] = useState(pageParams.offset);
  const [limit, setLimit] = useState(pageParams.limit);

  useEffect(() => {
    // Reset pagination on route change
    setOffset(pageParams.offset);
    setLimit(pageParams.limit);
  }, [type, pageParams]);

  const {
    data = { sortedData: [], total: 0 },
    loading,
    error,
  } = useStatefulApi<void, CuratedItemsData>(
    () => catalogueRepository.getCurations({ type, limit, offset }),
    [limit, offset, type],
  );

  const noProducts = error?.message === 'Authorisation error: invalid products';

  if (noProducts) {
    return (
      <Box pt="70px">
        <EmptySection
          title="No Products Available"
          subtitle="Oops! It seems there are no products assigned to your account. Please contact your CSM for assistance."
        />
      </Box>
    );
  }
  if (loading) {
    return (
      <Box data-testid="loading-state" p={10} textAlign="center">
        <Spinner size="xl" />
      </Box>
    );
  }
  if (data.total) {
    return (
      <GridItem data-testid={`table${type}s`} colSpan={2}>
        <Box mt="50px" maxWidth="1200px" margin="0 auto">
          <Heading as="h1" size="2xl" marginY="10">
            {`${type}s`}
          </Heading>
          {data?.sortedData.map((curation) => (
            <CuratedBanner item={curation} key={curation.id} />
          ))}
          <Pagination
            limit={limit}
            setLimit={setLimit}
            offset={offset}
            setOffset={setOffset}
            total={data.total}
          />
        </Box>
      </GridItem>
    );
  }
  return null;
}

export default CuratedIndex;
