import { format } from 'date-fns';
import JSZip from 'jszip';
import { getBinaryContent } from 'jszip-utils';
import { saveAs } from 'save-as';
import { DownloadIcon, RepeatClockIcon } from '@chakra-ui/icons';
import { catalogueRepository } from 'services/catalogueRepository';
import { FileProps, QueuedDownloads } from 'types/queuedDownloads';

export function getButtonIcon(tally: number, status: string) {
  if (status === 'downloading') return <RepeatClockIcon />;
  if (tally === 0) return undefined;
  return <DownloadIcon />;
}

export function getButtonText(tally: number, status: string) {
  if (status === 'downloading') return 'Downloading';
  if (tally === 1) return `${tally} Item`;
  if (tally > 1) return `${tally} Items`;
  return 'Add items to download';
}

type TriggerDownloadProps = {
  queuedDownloads: QueuedDownloads;
  selectedCount: number;
  setQueuedDownloads: (value: QueuedDownloads) => void;
  callback: () => void;
};

export async function triggerDownload({
  queuedDownloads,
  selectedCount = -1,
  setQueuedDownloads,
  callback,
}: TriggerDownloadProps) {
  const zip = new JSZip();
  const d = new Date();
  const zipFilename = `ContentHUB ${format(d, 'LLL do HH.mm')}.zip`;
  const selectedDownloads: FileProps[] = Object.values(queuedDownloads).filter(
    (download) => download.selected === true,
  );
  let count = 0;
  const presignedURLPromises = selectedDownloads.map((download) =>
    catalogueRepository.getDownloadAssetURL(download?.id),
  );
  const presignedURLs = await Promise.all(presignedURLPromises);

  const zipUtils = (filename: string, URL: string) =>
    getBinaryContent(URL, (err: Error, data: ArrayBuffer | string) => {
      if (err) {
        throw err; // or handle the error
      }
      zip.file(filename, data, { binary: true });
      count += 1;

      if (count === selectedCount) {
        zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, zipFilename);
          callback();
          setQueuedDownloads({});
          return true;
        });
      }
    });

  selectedDownloads.forEach((item, index) => {
    const filename = item.name;
    const URL = presignedURLs[index];

    zipUtils(filename as string, URL);
  });
}
