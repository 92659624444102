export type Asset = {
  assetLabel: string;
  id: string;
  name: string;
  status: string;
  titleId: string;
  type: string;
  url?: string;
};

export enum AssetLabels {
  SCORM = 'SCORM',
  CMI5 = 'CMI5',
  WebPDF = 'Web PDF',
  PrintablePDF = 'Printable PDF',
  MP4Video = 'MP4 Video',
  MP3Audio = 'MP3 Audio',
  PNGImage = 'PNG Image',
  JPGImage = 'JPG Image',
  SVGImage = 'SVG Image',
  WebPImage = 'WebP Image',
  AnimatedGIF = 'Animated GIF',
  AnimatedSV = 'Animated SVG',
  PowerPoint = 'PowerPoint',
  ExcelSheet = 'Excel Sheet',
  TranscriptDoc = 'Transcript Doc',
  ImageBundle = 'Image Bundle',
}
