import { ChangeEvent } from 'react';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { debounce } from 'helpers/debounce';

type SearchBarProps = {
  initialValue?: string;
  setSearchKeywords: (value: string) => void;
  setOffset: (value: number) => void;
};

function SearchBar({
  initialValue,
  setSearchKeywords,
  setOffset,
}: SearchBarProps) {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newKeywords = e.target.value ? e.target.value : '';
    setSearchKeywords(newKeywords);
    setOffset(0);
  };

  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="gray.300" />
      </InputLeftElement>
      <Input
        data-testid="inputSearchForTitles"
        variant="outline"
        placeholder="Enter search term..."
        defaultValue={initialValue}
        onChange={debounce(onChange)}
      />
    </InputGroup>
  );
}

export default SearchBar;
