import { useState } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { Asset } from 'types/asset';
import EmptySection from 'components/ui/EmptySection/component';

type ElearningPreviewFrameProps = {
  asset: Partial<Asset>;
  poster?: string;
};

function ElearningPreviewFrame({ asset, poster }: ElearningPreviewFrameProps) {
  const [showPreview, setShowPreview] = useState(false);

  if (!asset.url) {
    return (
      <EmptySection
        title="Course Preview Unavailable"
        subtitle="Sorry, we’re unable to create a preview of this course. Don’t worry, you
    can still download the eLearning package and it will work seamlessly on
    your platform."
      />
    );
  }

  if (!showPreview) {
    return (
      <Box
        w="90vw"
        maxW="1200px"
        height="40vh"
        margin="0 auto"
        backgroundColor="#dedede"
        backgroundImage={poster}
        backgroundSize="cover"
        backgroundPosition="center"
        borderRadius="10px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          data-testid="buttonShowPreview"
          onClick={() => setShowPreview(true)}
          boxShadow="0 0 20px rgba(0,0,0,0.2)"
        >
          Show Preview
        </Button>
      </Box>
    );
  }
  return (
    <iframe
      title="preview"
      style={{
        backgroundColor: 'black',
        width: '90vw',
        height: '85vh',
        maxWidth: '1200px',
        margin: '0 auto',
        borderRadius: '10px',
        overflow: 'hidden',
      }}
      src={asset.url}
    />
  );
}

export default ElearningPreviewFrame;
