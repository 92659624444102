import { ReactNode } from 'react';
import { Box, Heading, Tag, Image } from '@chakra-ui/react';

type HeaderSectionProps = {
  backgroundColor: string;
  color: string;
  heading: string;
  tags: string[];
  children?: ReactNode;
  summary?: string;
  imageUrl?: string;
  imageAlt?: string;
  banner?: string;
};

function HeaderSection({
  heading,
  summary,
  imageUrl,
  imageAlt,
  banner,
  tags,
  children,
  color,
  backgroundColor,
}: HeaderSectionProps) {
  return (
    <>
      <Box
        display="flex"
        flexDir={['column-reverse', 'column-reverse', 'row']}
        alignItems="center"
        justifyContent="space-around"
        backgroundImage={banner}
        backgroundSize="cover"
        backgroundPosition="center"
        p={[4, 8, 10]}
      >
        <Box
          data-testid="textTitlesInfoBox"
          textAlign="left"
          p={4}
          maxWidth={['90vw', '90vw', '45vw']}
          borderColor={backgroundColor}
          borderLeftWidth={5}
          tabIndex={0}
        >
          <Heading color={color} as="h1" size="2xl">
            {heading}
          </Heading>
          <Box
            borderRadius={5}
            mr={[0, 0, 2]}
            marginTop={[4, 6, 6]}
            whiteSpace="pre-line"
          >
            {summary}
          </Box>
          <Box marginTop={[2, 4, 6]}>
            {tags.map((tag) => (
              <Tag key={tag} mb={2} mr={2}>
                {tag}
              </Tag>
            ))}
          </Box>
        </Box>
        <Box
          height={['40vw', '140px', '200px']}
          width={['60vw', '200px', '355px']}
          borderRadius={10}
          overflow="hidden"
          boxShadow={imageUrl && '3px 3px 6px rgba(0,0,0,0.15)'}
        >
          {imageUrl && (
            <Image
              boxSize="100%"
              objectFit="cover"
              src={imageUrl}
              alt={imageAlt}
              tabIndex={0}
            />
          )}
        </Box>
      </Box>
      <Box py={2}>{children}</Box>
    </>
  );
}

export default HeaderSection;
