import formatIcons from 'components/icons/format';
import { Format } from 'types/format';

export const formatList = Object.values(Format).filter(
  (item) => item !== Format.FacilitateLead,
);

export function getIconForFormat(format: Format) {
  switch (format) {
    case Format.Read:
      return formatIcons.ReadIcon;
    case Format.Watch:
      return formatIcons.WatchIcon;
    case Format.Listen:
      return formatIcons.ListenIcon;
    case Format.Participate:
      return formatIcons.ParticipateIcon;
    default:
      return formatIcons.FacilitateIcon;
  }
}

export function getColourForFormat(format: Format) {
  switch (format) {
    case Format.Watch:
      return 'red';
    case Format.Read:
      return 'yellow';
    case Format.Listen:
      return 'green';
    case Format.Participate:
      return 'teal';
    case Format.FacilitateLead:
    case Format.Facilitate:
      return 'purple';
    default:
      return 'alpha';
  }
}
