type GetLoginUrlProps = {
  responseType: string;
  scope: string[];
};

export function getLoginUrl({ responseType, scope }: GetLoginUrlProps) {
  return `https://${
    process.env.REACT_APP_AWS_AUTH_DOMAIN
  }/oauth2/authorize?identity_provider=${
    process.env.REACT_APP_IDENTITY_PROVIDER
  }&redirect_uri=${
    window.location.origin
  }&response_type=${responseType}&client_id=${
    process.env.REACT_APP_COGNITO_APP_CLIENT_ID
  }&scope=${scope.join(' ')}`;
}
