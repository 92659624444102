import { useState, useContext } from 'react';
import { SimpleGrid, Spinner, Box, Flex, Heading } from '@chakra-ui/react';
import { catalogueRepository } from 'services/catalogueRepository';
import { useStatefulApi } from 'hooks/useStatefulApi';
import SidePanel from 'components/ui/SidePanel/component';
import SearchBar from 'components/ui/SearchBar/component';
import ItemCard from 'components/ui/ItemCard/component';
import { AnalyticsContext } from 'context/analyticsContext';
import Pagination from 'components/ui/Pagination/Pagination';
import EmptySection from 'components/ui/EmptySection/component';
import { TitlesData } from 'types/title';
import { Topic } from 'types/topic';
import { QueuedDownloads } from 'types/queuedDownloads';
import { usePaginationRouteParams } from 'hooks/usePaginationRouteParams';
import { sendAnalyticsFilterEvent } from './CatalogueIndex.utils';

type CatalogueIndexProps = {
  queuedDownloads: QueuedDownloads;
  searchKeywords?: string;
  selectedFormat?: string;
  selectedTopic?: string;
  topics: Topic[];
  setQueuedDownloads: (value: QueuedDownloads) => void;
  setSearchKeywords: (value: string) => void;
  setSelectedFormat: (value: string) => void;
  setSelectedTopic: (value: string) => void;
  setTopics: (value: Topic[]) => void;
};

function CatalogueIndex({
  queuedDownloads,
  searchKeywords,
  selectedFormat,
  selectedTopic,
  topics,
  setQueuedDownloads,
  setSearchKeywords,
  setSelectedFormat,
  setSelectedTopic,
  setTopics,
}: CatalogueIndexProps) {
  const sendAnalyticsEvent = useContext(AnalyticsContext);
  const pageParams = usePaginationRouteParams();
  const [offset, setOffset] = useState(pageParams.offset);
  const [limit, setLimit] = useState(pageParams.limit);

  const {
    data = { sortedData: [], total: 0 },
    loading,
    error,
  } = useStatefulApi<void, TitlesData>(
    () =>
      catalogueRepository.getAllTitles({
        topicId: selectedTopic,
        format: selectedFormat,
        keyword: searchKeywords,
        limit,
        offset,
      }),
    [limit, offset, searchKeywords, selectedFormat, selectedTopic],
    () => {
      if (searchKeywords) {
        sendAnalyticsEvent({
          category: 'Search',
          action: `Search keywords`,
          label: `${searchKeywords}`,
        });
      }
      sendAnalyticsFilterEvent({
        selectedFormat,
        selectedTopic,
        sendAnalyticsEvent,
      });
    },
  );

  const noProducts = error?.message === 'Authorisation error: invalid products';

  return (
    <Flex flexDir={{ base: 'column', lg: 'row' }}>
      <SidePanel
        selectedTopic={selectedTopic}
        setSelectedTopic={setSelectedTopic}
        selectedFormat={selectedFormat}
        setSelectedFormat={setSelectedFormat}
        topics={topics}
        setTopics={setTopics}
        setOffset={setOffset}
      />
      <Box flexGrow={2} ml={{ base: 0, lg: 4 }} mt={{ base: 4, lg: 0 }}>
        <Heading as="h1" size="2xl" mb="5">
          Titles
        </Heading>
        <SearchBar
          initialValue={searchKeywords}
          setSearchKeywords={setSearchKeywords}
          setOffset={setOffset}
        />
        {noProducts && (
          <Box pt="70px">
            <EmptySection
              title="No Products Available"
              subtitle="Oops! It seems there are no products assigned to your account. Please contact your CSM for assistance."
            />
          </Box>
        )}
        {loading && (
          <Box data-testid="loading-state" p={10} textAlign="center">
            <Spinner size="xl" />
          </Box>
        )}
        {!loading && data.total > 0 && (
          <>
            <SimpleGrid
              data-testid="tableTitles"
              mt={8}
              columns={4}
              spacing={4}
              minChildWidth="250px"
              justifyItems="center"
              overflow="hidden"
            >
              {data.sortedData.map((title) => (
                <ItemCard
                  key={title.id}
                  item={title}
                  queuedDownloads={queuedDownloads}
                  setQueuedDownloads={setQueuedDownloads}
                />
              ))}
            </SimpleGrid>
            <Pagination
              limit={limit}
              setLimit={setLimit}
              offset={offset}
              setOffset={setOffset}
              total={data.total}
            />
          </>
        )}
        {!error && !loading && !data.total && (
          <Box pt="70px">
            <EmptySection
              title="No matches found"
              subtitle="Please try another search"
            />
          </Box>
        )}
      </Box>
    </Flex>
  );
}

export default CatalogueIndex;
