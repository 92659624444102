import {
  useContext,
  useEffect,
  useState,
  KeyboardEvent,
  MutableRefObject,
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Flex,
  HStack,
  Button,
  Link,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { AnalyticsContext } from 'context/analyticsContext';
import { UserContext } from 'context/userContext';
import NavLink, { linkNames } from 'helpers/navigation';
import ColorModeSwitcher from 'components/ui/ColorModeSwitcher/component';
import { QueuedDownloads } from 'types/queuedDownloads';
import { getButtonIcon, getButtonText, triggerDownload } from './utils';

type NavBarProps = {
  mainRef?: MutableRefObject<HTMLDivElement | null>;
  queuedDownloads: QueuedDownloads;
  setQueuedDownloads: (value: QueuedDownloads) => void;
};

function NavBar({ mainRef, queuedDownloads, setQueuedDownloads }: NavBarProps) {
  const [showSkip, setShowSkip] = useState(false);
  const sendAnalyticsEvent = useContext(AnalyticsContext);
  const { userProducts } = useContext(UserContext);

  const isTrialUser = userProducts?.includes('Trial');
  const currentTally = Object.keys(queuedDownloads).filter(
    (d) => queuedDownloads[d].selected,
  ).length;

  const [currentStatus, setCurrentStatus] = useState('inactive');

  useEffect(() => {
    if (currentStatus === 'downloading') {
      triggerDownload({
        queuedDownloads,
        selectedCount: currentTally,
        setQueuedDownloads,
        callback: () => {
          setCurrentStatus('inactive');
        },
      });
    }
  });

  const triggerDownloadActions = () => {
    const downloads = Object.keys(queuedDownloads);
    sendAnalyticsEvent({
      category: 'Downloads',
      action: 'Downloading queued',
      label: downloads.toString(),
    });
    setCurrentStatus('downloading');
  };

  const triggerSkipToContent = (e: KeyboardEvent<HTMLAnchorElement>) => {
    if (e.key === 'Tab' && mainRef?.current) {
      e.preventDefault();
      setShowSkip(true);
    }
  };

  const focusMainContent = () => {
    mainRef?.current?.focus();
  };

  const skipButtonBackground = useColorModeValue('white', 'gray.800');

  return (
    <Flex
      m={5}
      as="header"
      role="banner"
      flexDir={{ base: 'column', lg: 'row' }}
      alignItems={{ base: 'flex-end', lg: 'center' }}
      justifyContent="space-between"
      fontSize={{ base: 'l', md: 'xl' }}
    >
      <HStack
        width={{ base: '100%', lg: 'auto' }}
        flexDir={{ base: 'column', md: 'row' }}
        spacing={8}
        justifyContent="center"
        position="relative"
      >
        <Link
          data-testid="imgContenthubLogo"
          as={RouterLink}
          to="/"
          onKeyDown={triggerSkipToContent}
        >
          <Text fontWeight={500} fontSize="1.66rem">
            Content<span style={{ fontWeight: 700 }}>HUB</span>
          </Text>
        </Link>
        {showSkip && (
          <Button
            autoFocus
            data-testid="buttonSkipToContent"
            onBlur={() => setShowSkip(false)}
            onClick={focusMainContent}
            onKeyDown={(e) => {
              if (e.key === 'Enter') focusMainContent();
            }}
            variant="outline"
            colorScheme="teal"
            size="sm"
            position="absolute"
            left={0}
            top={38}
            zIndex={1}
            backgroundColor={skipButtonBackground}
            _hover={{ bg: skipButtonBackground }}
          >
            Skip to content
          </Button>
        )}
        <HStack as="nav" role="navigation" spacing={4} display="flex">
          {Object.keys(linkNames).map((key) => (
            <NavLink id={linkNames[key]} key={key} to={key}>
              {linkNames[key]}
            </NavLink>
          ))}
          <ColorModeSwitcher />
        </HStack>
      </HStack>

      <Tooltip
        data-testid="popupTrialDownloadError"
        label="Downloading items is not possible as a trial customer."
        placement="bottom-end"
        isDisabled={!isTrialUser || !currentTally}
        size="sm"
      >
        <Button
          data-testid="buttonDownload"
          isDisabled={
            isTrialUser || !currentTally || currentStatus === 'downloading'
          }
          onClick={triggerDownloadActions}
          variant="solid"
          colorScheme="teal"
          size="sm"
          mt={{ base: 3, lg: 0 }}
          leftIcon={getButtonIcon(currentTally, currentStatus)}
          aria-label={`Download ${getButtonText(currentTally, currentStatus)}`}
        >
          {getButtonText(currentTally, currentStatus)}
        </Button>
      </Tooltip>
    </Flex>
  );
}

export default NavBar;
