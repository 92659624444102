import { useEffect, useContext, ChangeEvent } from 'react';
import {
  Select,
  Box,
  AccordionIcon,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';
import { AnalyticsContext } from 'context/analyticsContext';
import { catalogueRepository } from 'services/catalogueRepository';
import { Topic } from 'types/topic';

type TopicSelectProps = {
  topics: Topic[];
  selectedTopic?: string;
  setTopics: (value: Topic[]) => void;
  setOffset: (value: number) => void;
  setSelectedTopic: (value: string) => void;
};

function TopicSelector({
  selectedTopic,
  setSelectedTopic,
  setOffset,
  topics,
  setTopics,
}: TopicSelectProps) {
  const sendAnalyticsEvent = useContext(AnalyticsContext);

  useEffect(() => {
    catalogueRepository
      .getAllTopics()
      .then((records) => setTopics(records.data));
  }, [setTopics]);

  const onSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const topic = event.target.value;
    sendAnalyticsEvent({
      category: 'Topic',
      action: `Topic changed`,
      label: `${topic}`,
    });
    setSelectedTopic(topic);
    setOffset(0);
  };

  if (!topics) {
    return null;
  }

  return (
    <AccordionItem>
      <h2>
        <AccordionButton data-testid="buttonOpenTopics">
          <Box flex="1" textAlign="left">
            Topic
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <Select
          data-testid="selectTopic"
          size="sm"
          variant="filled"
          defaultValue={selectedTopic}
          onChange={onSelect}
          placeholder="All"
          aria-label="Select Topic"
        >
          {topics.length > 0 &&
            topics.map((topic) => (
              <option key={topic.id} value={topic.id}>
                {topic.topicName}
              </option>
            ))}
        </Select>
      </AccordionPanel>
    </AccordionItem>
  );
}

export default TopicSelector;
