import { useContext } from 'react';
import { Button, Box } from '@chakra-ui/react';
import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import { AnalyticsContext } from 'context/analyticsContext';
import { isObject } from 'helpers/object';
import { Asset } from 'types/asset';
import { QueuedDownloads } from 'types/queuedDownloads';

type AddDownloadListProps = {
  assets: Asset[];
  colorScheme: string;
  queuedDownloads: QueuedDownloads;
  titleCode: string;
  setQueuedDownloads: (value: QueuedDownloads) => void;
  availableAs?: string[];
};

function AddDownloadList({
  titleCode,
  availableAs,
  queuedDownloads,
  setQueuedDownloads,
  colorScheme,
  assets,
}: AddDownloadListProps) {
  const sendAnalyticsEvent = useContext(AnalyticsContext);

  const assetTypeIsAdded = (assetType: string) => {
    const downloadKey = `${titleCode}-${assetType}`;
    return (
      isObject(queuedDownloads[downloadKey]) &&
      queuedDownloads[downloadKey].selected === true
    );
  };

  const toggleQueuedDownload = async (assetType: string) => {
    const downloadKey = `${titleCode}-${assetType}`;

    const amendedQueuedDownloads = { ...queuedDownloads };

    if (!assetTypeIsAdded(assetType)) {
      if (!isObject(amendedQueuedDownloads[downloadKey])) {
        const asset = assets.find(({ assetLabel }) => assetLabel === assetType);
        amendedQueuedDownloads[downloadKey] = {
          id: asset?.id,
          name: asset?.name,
        };
        amendedQueuedDownloads[downloadKey].selected = true;
        amendedQueuedDownloads[downloadKey].titleCode = titleCode;
        setQueuedDownloads(amendedQueuedDownloads);
      } else {
        amendedQueuedDownloads[downloadKey].selected = true;
        setQueuedDownloads(amendedQueuedDownloads);
      }
    } else {
      amendedQueuedDownloads[downloadKey].selected = false;
      setQueuedDownloads(amendedQueuedDownloads);
    }
    sendAnalyticsEvent({
      category: 'Downloads',
      action: 'Download added',
      label: `${downloadKey}`,
    });
  };

  if (!Array.isArray(availableAs)) {
    return null;
  }

  return (
    <Box>
      Add to basket:
      {availableAs.map((assetType) => (
        <Button
          data-testid={`button${assetType.replace(' ', '')}`}
          marginLeft={4}
          key={`${titleCode}-${assetType}-action`}
          fontSize="sm"
          backgroundColor={
            assetTypeIsAdded(assetType) ? `${colorScheme}.100` : 'grey.200'
          }
          rightIcon={
            assetTypeIsAdded(assetType) ? (
              <CloseIcon data-testid="iconAddToBasketCross" color="red.500" />
            ) : (
              <AddIcon data-testid="iconAddToBasketPlus" />
            )
          }
          onClick={() => toggleQueuedDownload(assetType)}
        >
          {assetType}
        </Button>
      ))}
    </Box>
  );
}

export default AddDownloadList;
