import { Amplify, Auth } from 'aws-amplify';

const cognitoOptions = {
  region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
};

export const oauth = {
  domain: process.env.REACT_APP_AWS_AUTH_DOMAIN,
  redirectSignIn: window.location.origin,
  redirectSignOut: `${window.location.origin}/login`,
  responseType: 'code',
  scope: ['email', 'openid'],
};

export const amplifyConfig = () => {
  Amplify.configure({ Auth: cognitoOptions });
  Auth.configure({ oauth });
  return Auth;
};
