import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, useColorModeValue } from '@chakra-ui/react';

export const linkNames: Record<string, string> = {
  '/campaigns': 'Campaigns',
  '/pathways': 'Pathways',
  '/titles': 'Titles',
};

type NavLinkProps = {
  id: string;
  to: string;
  children: ReactNode;
};

function NavLink({ id, to, children }: NavLinkProps) {
  return (
    <Link
      data-testid={`button${id}`}
      as={RouterLink}
      to={to}
      px={2}
      py={1}
      rounded="md"
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
      }}
    >
      {children}
    </Link>
  );
}

export default NavLink;
