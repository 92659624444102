import { useEffect, useState, useContext } from 'react';
import ReactGA, { EventArgs } from 'react-ga';
import { useLocation } from 'react-router';
import { UserContext } from 'context/userContext';

export function useAnalytics() {
  const { userId } = useContext(UserContext);
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const isDev =
      !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    if (process.env.REACT_APP_GA_TRACKING_ID && userId) {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
        debug: isDev,
        gaOptions: {
          userId,
        },
      });
      setInitialized(true);
    } else {
      setInitialized(false);
    }
  }, [userId]);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location, initialized]);

  const sendEvent = (payload: EventArgs) =>
    initialized && ReactGA.event(payload);

  return sendEvent;
}
