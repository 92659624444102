import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Box, Heading, Text, Tooltip } from '@chakra-ui/react';
import CurationIcons from 'components/icons/curation';
import { CuratedItem } from 'types/curatedItem';

type CuratedBannerProps = {
  item: CuratedItem;
};

function CuratedBanner({ item: curation }: CuratedBannerProps) {
  const linkData = {
    pathname: `/${curation.isCampaign ? 'campaigns' : 'pathways'}/${
      curation.id
    }`,
    state: { curation },
  };

  return (
    <Link data-testid={`curatedBunner-${curation.id}`} to={linkData}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Box flex={1} display="flex" alignItems="flex-start" marginRight="4vw">
          <Tooltip
            hasArrow
            label={curation.type}
            placement="right"
            fontSize="md"
          >
            <Box
              boxShadow="md"
              display="inline-block"
              p={[0.5, 1, 1.5]}
              mr={[2, 3, 4]}
              borderRadius={100}
              backgroundColor={curation.isCampaign ? 'teal.100' : 'purple.100'}
            >
              {curation.isCampaign ? (
                <CurationIcons.CampaignIcon
                  role="img"
                  aria-label={curation.type}
                  boxSize={[5, 7, 9]}
                  color="teal.600"
                />
              ) : (
                <CurationIcons.PathwayIcon
                  role="img"
                  aria-label={curation.type}
                  boxSize={[5, 7, 9]}
                  color="purple.600"
                />
              )}
            </Box>
          </Tooltip>
          <Box borderBottom="1px solid grey" flex={1}>
            <Heading size="2xl" mb="5px">
              {curation.name}
            </Heading>
            {curation.isCampaign && (
              <Text>
                {format(curation.startDate, 'LLL do')} -{' '}
                {format(curation.endDate, 'LLL do')}
              </Text>
            )}
            {!curation.isCampaign && (
              <Text size="xl">{curation.numItems} Items</Text>
            )}
          </Box>
        </Box>
        <Box
          height={['50px', '120px', '150px']}
          width={['50px', '140px', '280px']}
          backgroundImage={curation.imageUrl}
          backgroundSize="cover"
          backgroundPosition="center"
          borderRadius={[100, 10, 10]}
          overflow="hidden"
        />
      </Box>
    </Link>
  );
}

export default CuratedBanner;
