import { Box, Spinner } from '@chakra-ui/react';
import { Asset } from 'types/asset';

type DocPreviewFrameProps = {
  asset: Partial<Asset>;
};

function DocPreviewFrame({ asset }: DocPreviewFrameProps) {
  if (!asset) {
    return (
      <Box p={10} textAlign="center">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <iframe
      title="preview"
      style={{
        backgroundColor: 'black',
        width: '90vw',
        height: '90vh',
        maxWidth: '1200px',
        margin: '0 auto',
      }}
      src={asset.url}
    />
  );
}

export default DocPreviewFrame;
