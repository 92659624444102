import { ComponentPropsWithoutRef } from 'react';
import { Heading, Link, Text } from '@chakra-ui/react';

export const markdownTheme = (currentColor: string) => ({
  p: (props: ComponentPropsWithoutRef<'p'>) => (
    <Text size="lg">{props.children}</Text>
  ),
  h1: (props: ComponentPropsWithoutRef<'h1'>) => (
    <Heading as="h3" mt={10} mb={6} fontWeight={500} size="lg">
      {props.children}
    </Heading>
  ),
  h2: (props: ComponentPropsWithoutRef<'h2'>) => (
    <Heading as="h4" mt={2} mb={3} fontWeight={500} size="md">
      {props.children}
    </Heading>
  ),
  h3: (props: ComponentPropsWithoutRef<'h3'>) => (
    <Heading as="h5" mt={2} mb={2} fontWeight={500} size="sm">
      {props.children}
    </Heading>
  ),
  a: (props: ComponentPropsWithoutRef<'a'>) => (
    <Link
      href={props.href}
      target="_blank"
      color={currentColor}
      fontWeight={500}
    >
      {props.children}
    </Link>
  ),
});
