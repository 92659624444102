import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Box, Link } from '@chakra-ui/react';

function BackButton() {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <Box mb={2}>
      <Link
        data-testid="buttonGoBack"
        fontWeight={300}
        onClick={goBack}
        onKeyDown={(e) => e.key === 'Enter' && goBack()}
        tabIndex={0}
      >
        <ChevronLeftIcon />
        Go Back
      </Link>
    </Box>
  );
}

export default BackButton;
