import { Icon, IconProps } from '@chakra-ui/icons';

function CampaignIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0z" />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M16 2v4M8 2v4M3 9h18M19 4H5a2 2 0 00-2 2v13a2 2 0 002 2h14a2 2 0 002-2V6a2 2 0 00-2-2z"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M7.013 12.729a.25.25 0 10.002.5.25.25 0 00-.002-.5M12.013 12.729a.25.25 0 10.002.5.25.25 0 00-.002-.5M17.013 12.729a.25.25 0 10.002.5.25.25 0 00-.002-.5M7.013 16.729a.25.25 0 10.002.5.25.25 0 00-.002-.5M12.013 16.729a.25.25 0 10.002.5.25.25 0 00-.002-.5"
        />
      </g>
    </Icon>
  );
}

function PathwayIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0z" />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M7.914 4.586a2 2 0 11-2.828 2.828 2 2 0 012.828-2.828M12.234 18H8.627C7.176 18 6 16.657 6 15.001v-.003c0-1.656 1.176-2.999 2.627-2.999h6.745c1.451 0 2.627-1.343 2.627-2.999v0C18 7.341 16.822 5.998 15.37 6l-4.12.005M16.34 20.184l2.889-1.709a.552.552 0 000-.95l-2.889-1.709a.556.556 0 00-.84.479v3.41c0 .431.469.699.84.479z"
        />
      </g>
    </Icon>
  );
}

const CurationIcons = {
  CampaignIcon,
  PathwayIcon,
};

export default CurationIcons;
