import { useContext } from 'react';
import {
  Menu,
  MenuButton,
  IconButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { AddIcon, CloseIcon, CheckIcon } from '@chakra-ui/icons';
import { AnalyticsContext } from 'context/analyticsContext';
import { isObject } from 'helpers/object';
import { Asset } from 'types/asset';
import { QueuedDownloads } from 'types/queuedDownloads';

type AddDownloadMenuType = {
  assets: Asset[];
  queuedDownloads: QueuedDownloads;
  titleCode: string;
  setQueuedDownloads: (value: QueuedDownloads) => void;
  availableAs?: string[];
};

function AddDownloadMenu({
  assets,
  queuedDownloads,
  titleCode,
  setQueuedDownloads,
  availableAs,
}: AddDownloadMenuType) {
  const sendAnalyticsEvent = useContext(AnalyticsContext);

  const assetTypeIsAdded = (assetType: string) => {
    const downloadKey = `${titleCode}-${assetType}`;
    return (
      isObject(queuedDownloads[downloadKey]) &&
      queuedDownloads[downloadKey].selected === true
    );
  };

  const toggleQueuedDownload = async (assetType: string) => {
    const downloadKey = `${titleCode}-${assetType}`;

    const amendedQueuedDownloads = { ...queuedDownloads };

    if (!assetTypeIsAdded(assetType)) {
      if (!isObject(amendedQueuedDownloads[downloadKey])) {
        const asset = assets.find(({ assetLabel }) => assetLabel === assetType);
        amendedQueuedDownloads[downloadKey] = {
          id: asset?.id,
          name: asset?.name,
        };
        amendedQueuedDownloads[downloadKey].selected = true;
        amendedQueuedDownloads[downloadKey].titleCode = titleCode;
        setQueuedDownloads(amendedQueuedDownloads);
      } else {
        amendedQueuedDownloads[downloadKey].selected = true;
        setQueuedDownloads(amendedQueuedDownloads);
      }
      sendAnalyticsEvent({
        category: 'Downloads',
        action: 'Download added',
        label: `${downloadKey}`,
      });
    } else {
      amendedQueuedDownloads[downloadKey].selected = false;
      sendAnalyticsEvent({
        category: 'Downloads',
        action: 'Download removed',
        label: `${downloadKey}`,
      });
      setQueuedDownloads(amendedQueuedDownloads);
    }
  };

  let downloadTally = 0;
  Object.keys(queuedDownloads).forEach((k) => {
    downloadTally +=
      queuedDownloads[k].selected && queuedDownloads[k].titleCode === titleCode
        ? 1
        : 0;
    return k;
  });
  const isQueued = downloadTally > 0;

  if (!Array.isArray(availableAs)) {
    return null;
  }

  return (
    <Menu placement="bottom-end">
      <MenuButton
        data-testid="buttonAddDownload"
        onClick={(e) => {
          e.stopPropagation();
        }}
        aria-label="Add Download"
        as={IconButton}
        backgroundColor={isQueued ? 'purple.200' : 'grey.200'}
        icon={isQueued ? <CheckIcon /> : <AddIcon />}
      >
        {downloadTally > 0 ? downloadTally : 'Add'}
      </MenuButton>
      <MenuList data-testid="popupAssetSelection">
        {availableAs.map((assetType) => (
          <MenuItem
            key={`${titleCode}-${assetType}-action`}
            fontSize="sm"
            icon={
              assetTypeIsAdded(assetType) ? (
                <CloseIcon color="red.500" />
              ) : (
                <AddIcon />
              )
            }
            onClick={(e) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              toggleQueuedDownload(assetType);
            }}
          >
            {assetType}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

export default AddDownloadMenu;
