import { useContext } from 'react';
import { FaMoon, FaSun } from 'react-icons/fa';
import { useColorMode, useColorModeValue, IconButton } from '@chakra-ui/react';
import { AnalyticsContext } from 'context/analyticsContext';

function ColorModeSwitcher() {
  const sendAnalyticsEvent = useContext(AnalyticsContext);
  const { toggleColorMode } = useColorMode();
  const text = useColorModeValue('dark', 'light');
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);
  const toggleColourMode = () => {
    sendAnalyticsEvent({
      category: 'Navbar',
      action: 'Colour mode toggled',
      label: `${text}`,
    });
    toggleColorMode();
  };

  return (
    <IconButton
      data-testid="buttonMode"
      size="md"
      fontSize="lg"
      aria-label={`Switch to ${text} mode`}
      variant="ghost"
      color="current"
      marginLeft="2"
      onClick={toggleColourMode}
      icon={<SwitchIcon />}
    />
  );
}

export default ColorModeSwitcher;
