import { useContext, useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, useColorModeValue } from '@chakra-ui/react';
import { useAnalytics } from 'hooks/useAnalytics';
import { AnalyticsContext } from 'context/analyticsContext';
import { UserContext } from 'context/userContext';
import { oauth } from 'config/amplify-config';
import NavBar from 'components/ui/NavLink/component';
import LogOutButton from 'components/ui/LogOutButton/component';
import { QueuedDownloads } from 'types/queuedDownloads';
import { getLoginUrl } from 'helpers/login';

import logo from 'assets/thrive-logo.png';
import logoDark from 'assets/thrive-logo-dark.png';

type PrivateRoutesProps = {
  queuedDownloads: QueuedDownloads;
  setQueuedDownloads: (value: QueuedDownloads) => void;
};

function PrivateRoutes({
  queuedDownloads,
  setQueuedDownloads,
}: PrivateRoutesProps) {
  const mainRef = useRef<HTMLDivElement | null>(null);
  const sendAnalyticsEvent = useAnalytics();
  const { userId } = useContext(UserContext);

  const thriveLogo = useColorModeValue(logo, logoDark);

  useEffect(() => {
    if (!userId) {
      window.location.href = getLoginUrl(oauth);
    }
  }, [userId]);

  if (!userId) {
    return null;
  }
  return (
    <AnalyticsContext.Provider value={sendAnalyticsEvent}>
      <Box minH="97vh" boxSize="border-box">
        <NavBar
          mainRef={mainRef}
          queuedDownloads={queuedDownloads}
          setQueuedDownloads={setQueuedDownloads}
        />
        <Box ref={mainRef} tabIndex={-1} m={5} pb="5rem" as="main" role="main">
          <Outlet />
        </Box>
        <Box
          as="footer"
          pos="absolute"
          bottom={0}
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-evenly"
        >
          <Box width="100px">
            <img width="100%" src={thriveLogo} alt="Thrive logo" />
          </Box>
          <LogOutButton />
        </Box>
      </Box>
    </AnalyticsContext.Provider>
  );
}

export default PrivateRoutes;
