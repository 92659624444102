import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import illustration from 'assets/illustration.png';
import illustrationDark from 'assets/illustration-dark.png';

type EmptySectionProps = {
  title: string;
  subtitle: string;
};
function EmptySection({ title, subtitle }: EmptySectionProps) {
  const imageSrc = useColorModeValue(illustration, illustrationDark);
  return (
    <Box
      data-testid="empty-section"
      w="60vw"
      maxW="800px"
      margin="0 auto"
      display="flex"
      alignItems="center"
      gap="10px"
      flexDirection="column"
    >
      <img src={imageSrc} alt="There is no option" />
      <Text fontWeight={500}>{title}</Text>
      <Text align="center">{subtitle}</Text>
    </Box>
  );
}

export default EmptySection;
