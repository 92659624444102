import { Auth } from 'aws-amplify';
import { Box, Link } from '@chakra-ui/react';

function LogOutButton() {
  const logout = () => Auth.signOut();

  return (
    <Box>
      <Link
        data-testid="buttonLogOut"
        fontWeight={300}
        onClick={logout}
        onKeyDown={(e) => e.key === 'Enter' && logout()}
        tabIndex={0}
      >
        Log Out
      </Link>
    </Box>
  );
}

export default LogOutButton;
