import { useEffect, useState } from 'react';
import { Box, Spinner } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { catalogueRepository } from 'services/catalogueRepository';
import BackButton from 'components/ui/BackButton/component';
import PreviewFrame from 'components/ui/PreviewFrame/component';
import { QueuedDownloads } from 'types/queuedDownloads';
import { Title } from 'types/title';
import TitleHeader from './TitleHeader/component';

type TitleDetailProps = {
  queuedDownloads: QueuedDownloads;
  setQueuedDownloads: (value: QueuedDownloads) => void;
};

function TitleDetail({
  queuedDownloads,
  setQueuedDownloads,
}: TitleDetailProps) {
  const location = useLocation();
  const [title, setTitle] = useState<Title>();

  useEffect(() => {
    if (title) {
      return;
    }
    if (location.state && location.state.curation) {
      setTitle(location.state.item);
      return;
    }
    const id = location.pathname.split('/').pop();
    catalogueRepository.getTitleById(setTitle, id);
  }, [title, location]);

  if (!title) {
    return (
      <Box p={10} textAlign="center">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <>
      <Box mb={2}>
        <BackButton />
        <TitleHeader
          title={title}
          queuedDownloads={queuedDownloads}
          setQueuedDownloads={setQueuedDownloads}
        />
      </Box>
      <PreviewFrame title={title} />
    </>
  );
}

export default TitleDetail;
