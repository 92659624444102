import { useState, useEffect } from 'react';
import { Box, Spinner } from '@chakra-ui/react';
import { catalogueRepository } from 'services/catalogueRepository';
import { Asset, AssetLabels } from 'types/asset';
import { Title } from 'types/title';
import DocPreviewFrame from './DocPreviewFrame/component';
import ElearningPreviewFrame from './ELearningPreviewFrame/component';
import ImagePreviewFrame from './ImagePreviewFrame/component';
import VideoPreviewFrame from './VideoPreviewFrame/component';
import { getPreviewAsset } from './util';

type PreviewFrameProps = {
  title: Title;
};

function PreviewFrame({ title }: PreviewFrameProps) {
  const [asset, setAsset] = useState<Partial<Asset>>();

  useEffect(() => {
    if (title && !title.availableAs) {
      return;
    }

    const previewAsset = getPreviewAsset(title);

    catalogueRepository
      .getAssetPresignedURL(title.assetPreviewId)
      .then((result) => {
        switch (previewAsset?.assetLabel) {
          case AssetLabels.SCORM:
          case AssetLabels.CMI5:
            setAsset({
              url: result,
              type: `application/${previewAsset?.assetLabel}`,
            });
            break;
          default:
            setAsset({
              url: result,
              type: previewAsset?.type,
            });
            break;
        }
      });
  }, [title]);

  if (!asset) {
    return (
      <Box p={10} textAlign="center">
        <Spinner size="xl" />
      </Box>
    );
  }

  switch (asset.type) {
    case 'application/SCORM':
    case 'application/CMI5':
      return <ElearningPreviewFrame asset={asset} poster={title.imageUrl} />;
    case 'video/mp4':
    case 'audio/ogg':
    case 'audio/mpeg':
    case 'audio/wav':
      return <VideoPreviewFrame asset={asset} poster={title.imageUrl} />;
    case 'image/gif':
    case 'image/jpeg':
    case 'image/png':
    case 'image/webp':
    case 'image/svg+xml':
      return <ImagePreviewFrame asset={asset} />;
    case 'application/pdf':
      return <DocPreviewFrame asset={asset} />;
    default:
      return null;
  }
}

export default PreviewFrame;
