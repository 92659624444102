import {
  DependencyList,
  EffectCallback,
  useCallback,
  useEffect,
  useState,
} from 'react';

type StatefulApiError = Record<string, unknown> | null;

export type StatefulApiReturnParams<R> = {
  data?: R;
  error: StatefulApiError;
  loading: boolean;
};

export const useStatefulApi = <P, R>(
  req: (params: P) => Promise<R>,
  deps?: DependencyList,
  onSuccess?: (response: R, params: P) => void,
  onError?: (error: StatefulApiError) => void,
): StatefulApiReturnParams<R> => {
  const [data, setData] = useState<R>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<StatefulApiError>(null);

  const sendRequest = useCallback(
    (params: P) => {
      setError(null);
      setLoading(true);
      req(params)
        .then((response: R) => {
          setData(response);
          setLoading(false);
          if (onSuccess) {
            onSuccess(response, params);
          }
        })
        .catch((reject: StatefulApiError) => {
          setError(reject);
          setLoading(false);
          if (onError) {
            onError(reject);
          }
        });
    },
    [onError, onSuccess, req],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(sendRequest as EffectCallback, deps || []);

  return { data, loading, error };
};
