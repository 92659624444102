type HandleSelectChangeProps = {
  value: number;
  setLimit: (value: number) => void;
  setOffset: (value: number) => void;
};

export function handleSelectChange({
  value,
  setLimit,
  setOffset,
}: HandleSelectChangeProps) {
  setLimit(value);
  setOffset(0);
}

type PaginationTextProps = {
  offset: number;
  limit: number;
  total: number;
};

export function paginationText({ offset, limit, total }: PaginationTextProps) {
  return `${offset + 1}-${
    offset + Number(limit) >= total ? total : offset + Number(limit)
  }`;
}
